import React from "react";
import Footer from "@/components/footer";
import Layout from "@/components/layout";
import ContactInfos from "@/components/contact-infos";
import GoogleMap from "@/components/google-map";
import ContactForm from "@/components/contact-form";
import PageBanner from "@/components/page-banner";
import SearchContextProvider from "@/context/search-context";
import MenuContextProvider from "@/context/menu-context";
import HeaderOne from "@/components/header-one";
import SEO from "@/components/seo";

const ContactPage = () => {
  return (
    <MenuContextProvider>
      <SearchContextProvider>
        <SEO
          title="Solxk - Kontaktiere uns"
          description="Kontaktieren Sie sich mit Solxk.Kontaktieren Sie uns für Anfragen, Unterstützung oder mehr über unsere Dienste in Bezug auf Softwareentwicklung und Cybersicherheit."
        />
        <Layout PageTitle="Contact">
          <HeaderOne />
          <PageBanner title="Kontaktiere uns" name="Contact" />
          <ContactForm />
          <GoogleMap extraClass="contact-page" />
          <ContactInfos />
          <Footer />
        </Layout>
      </SearchContextProvider>
    </MenuContextProvider>
  );
};

export default ContactPage;
